<template>
    <div class="page-content">
        <div class="grid">
            <div class="row no-gutters">
                <div class="col l-12 m-12 c-12">
                    <div class="page-list-body">
                        <div class="row no-gutters page-list-header">
                            <div class="col l-6 m-6 c-12">
                                <h4 class="page-list-title"><md-icon>list_alt</md-icon> <span>Phiếu cấp dầu</span></h4>
                            </div>
                            <div class="col l-6 m-6 c-12">
                                <div class="page-list-action">
                                    <md-button v-shortkey="['ctrl', 'p']" @shortkey="print()" @click="print()" class="md-raised md-view btn-add"><span>P</span>rint<md-tooltip>Print</md-tooltip></md-button>
                                </div>
                            </div>
                        </div>
                        <div class="form-body">
                            <div id="print-view" style="width: 1000px; display: flex; flex-direction: column; justify-content: center; margin: 10px auto;">
                                <div style="display: flex; align-items: center;">
                                    <div style="width: 200px;">
                                        <img style="height: 50px; width: auto;" src="../../../assets/images/logo.png" />
                                    </div>
                                    <div style="width: 400px; text-align: center; text-transform: uppercase; font-weight: bold; font-size: 16px;">
                                        Công ty TNHH MTV TM và tiếp vận Bảo Tín
                                    </div>
                                    <div style="width: 400px; text-align: center; text-transform: uppercase; font-weight: bold; font-size: 16px;">
                                        {{ entity.company.companyName }}
                                    </div>
                                </div>
                                <div style="display: flex; align-items: center; margin-top: 20px; justify-content: center;">
                                    <div style="font-size: 24px; font-weight: bold; text-transform: uppercase;">Phiếu cấp dầu</div>
                                </div>
                                <div style="display: flex; align-items: center; margin-top: 10px; justify-content: left;">
                                    <div style="font-size: 15px; font-weight: bold;">Địa điểm nhận dầu: </div>
                                    <div style="font-size: 15px; padding-left: 5px;">{{ entity.fuelAddress }}</div>
                                </div>
                                <div style="display: flex; align-items: center; margin-top: 10px; justify-content: left;">
                                    <div style="font-size: 15px; font-weight: bold;">Biển số xe: </div>
                                    <div style="font-size: 15px; padding-left: 5px;">{{ entity.vehicle.licensePlate }}</div>
                                </div>
                                <div style="display: flex; align-items: center; margin-top: 10px; justify-content: left;">
                                    <div style="font-size: 15px; font-weight: bold;">Họ và tên lái xe: </div>
                                    <div style="font-size: 15px; padding-left: 5px;">{{ entity.driver.fullName }}</div>
                                </div>
                                <div style="display: flex; justify-content: space-between; align-items: center; margin-top: 10px;">
                                    <div style="width: 50%; display: flex; align-items: center; margin-top: 10px; justify-content: left;">
                                        <div style="font-size: 15px; font-weight: bold;">Ngày lập phiếu: </div>
                                        <div style="font-size: 15px; padding-left: 5px;">{{ entity.createdDate }}</div>
                                    </div>
                                    <div style="display: flex; align-items: center; margin-top: 10px; justify-content: left;">
                                        <div style="font-size: 15px; font-weight: bold;">Ngày đổ dầu thực tế: </div>
                                        <div style="font-size: 15px; padding-left: 5px;">....................................</div>
                                    </div>
                                </div>
                                
                                <div style="display: flex; align-items: center; margin-top: 10px; justify-content: left;">
                                    <div style="font-size: 15px; font-weight: bold;">Số lượng cấp: </div>
                                    <div style="font-size: 15px; padding-left: 5px;">{{ formatNumber(entity.quantity) }} lít
                                        <span v-if="entity.fuelType !== ''">{{ '(' + entity.fuelType + ')' }}</span>
                                    </div>
                                </div>
                                <div style="display: flex; align-items: center; margin-top: 10px; justify-content: left;">
                                    <div style="font-size: 15px; font-weight: bold;">Định mức nhiên liệu: </div>
                                    <div style="font-size: 15px; padding-left: 5px;">{{ formatNumber(parseFloat(entity.quantity * 0.4).toFixed(0)) }} km</div>
                                </div>
                                <div style="display: flex; align-items: center; margin-top: 10px; justify-content: left;">
                                    <div style="font-size: 15px; font-weight: bold;">Đơn giá: </div>
                                    <div style="font-size: 15px; padding-left: 5px;">1{{ formatNumber(entity.price) }} VNĐ/lít</div>
                                </div>
                                <div style="display: flex; align-items: center; margin-top: 10px; justify-content: left;">
                                    <div style="font-size: 15px; font-weight: bold;">Thành tiền: </div>
                                    <div style="font-size: 15px; padding-left: 5px;">{{ formatNumber(entity.amount) }} VNĐ</div>
                                </div>
                                <div style="display: flex; align-items: center; margin-top: 10px; justify-content: left;">
                                    <div style="font-size: 15px; font-weight: bold;">Số tiền bằng chữ: </div>
                                    <div style="font-size: 15px; padding-left: 5px; font-style: italic;">{{ entity.textAmount }}</div>
                                </div>

                                <div style="display: flex; align-items: center; margin-top: 30px; justify-content: space-between;">
                                    <div style="display: flex; flex-direction: column; align-items: center;">
                                        <div style="font-weight: bold; font-size: 16px;">Người nhận</div>
                                        <div style="font-style: italic; font-size: 13px;">(ký, ghi rõ họ tên)</div>
                                    </div>
                                    <div style="display: flex; flex-direction: column; align-items: center;">
                                        <div style="font-weight: bold; font-size: 16px;">Người lập phiếu</div>
                                        <div style="font-style: italic; font-size: 13px;">(ký, ghi rõ họ tên)</div>
                                    </div>
                                    <div style="display: flex; flex-direction: column; align-items: center;">
                                        <div style="font-weight: bold; font-size: 16px;">Trưởng đơn vị cấp dầu</div>
                                        <div style="font-style: italic; font-size: 13px;">(ký, ghi rõ họ tên)</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import fuelService from '../../../api/fuelService';
    import messageBox from '../../../utils/messageBox';
    import { mapActions } from 'vuex';

    export default ({
        metaInfo: {
            title: 'Phiếu cấp dầu'
        },
        data() {
            return {
                id: 0,
                entity: { }
            }
        },
        created(){
            this.id = this.$route.params.id;
            if(this.id > 0){
                this.getById();
            }
        },
        methods: {
            ...mapActions('common', ['setLoading']),

            print () {
                this.$htmlToPaper('print-view');
            },

            getById(){
                this.setLoading(true);
                fuelService.getById(this.id).then((response) => {
                    if(response.statusCode == 200){
                        this.entity = response.data;
                    }
                    else{
                        messageBox.showWarning(response.data);
                    }
                }).finally(() => { this.setLoading(false); });
            },

            back(){
                this.$router.push('/fuel');
            }
        }
    })
</script>